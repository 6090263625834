<template>
  <div
    class="balance-card"
    v-if="transactions !== undefined && transactions.length > 0"
  >
    <div>
      <div class="bb-intro-top">
        <div class="bb-icon"></div>
        <a class="b-icon-title">
          {{ $t("toefl.balance.Transactions") }}
        </a>
      </div>
      <div class="b-info">
        <el-table :data="transactions" style="width: 100%;margin-bottom:20px">
          <el-table-column :label="$t('toefl.balance.Date')">
            <template slot-scope="scope">
              <i class="el-icon-date"></i>
              <span style="margin-left: 10px">{{
                instant.formatDateTime(scope.row.updated_at)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="point" :label="$t('toefl.T-Coin')">
            <template slot-scope="scope">
              <div v-if="scope.row.category === 'income'">
                <h6 class="balance-price" style="font-size:20px;margin:0">
                  +{{ scope.row.point }}
                </h6>
                <span class="balance-name">{{ $t("toefl.T-Coin") }}</span>
              </div>
              <div v-if="scope.row.category === 'expense'">
                <h6
                  class="balance-price text-danger"
                  style="font-size:20px;margin:0"
                >
                  -{{ scope.row.point }}
                </h6>
                <span class="balance-name text-danger">{{
                  $t("toefl.T-Coin")
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('toefl.balance.Amount')">
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.category === 'income' &&
                    scope.row.tradable !== null &&
                    scope.row.tradable.price
                "
              >
                <h6 class="balance-price" style="font-size:20px;margin:0">
                  {{ Number(scope.row.tradable.price) }}
                </h6>
                <span class="balance-name">
                  {{ scope.row.tradable.price_currency }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="
              `${$t('toefl.balance.Purchase')}/${$t('toefl.balance.Deposit')}`
            "
            width="180"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.category === 'income'">{{
                reason[scope.row.reason]
              }}</el-tag>
              <el-tag v-if="scope.row.category === 'expense'" type="danger">{{
                reason[scope.row.reason]
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            :label="$t('toefl.balance.Notes')"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.description }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Toefl from "@/apis/toefl.js";
import { instant } from "@ivy-way/material";

export default {
  data() {
    return {
      transactions: [],
      reason: {
        CONSUME: this.$t("toefl.balance.Purchase"),
        STORED: this.$t("toefl.balance.Deposit"),
        EMAIL_VERIFY: this.$t("toefl.balance.Email Verification"),
        REFERRAL: this.$t("toefl.balance.T-Coin Reward"),
        REFUND: this.$t("toefl.balance.Refund")
      }
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  async mounted() {
    this.getTransactions();
  },

  methods: {
    async getTransactions() {
      const res = await Toefl.getTransactions();
      this.transactions = res.point_transactions;
    }
  }
};
</script>

<style scoped>
.b-info .balance-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
@media screen and (max-width: 400px) {
  .b-info {
    padding: 0 15px;
  }
}
</style>
