<template>
  <div class="balance-card" v-if="trades.length > 0">
    <div>
      <div class="bb-intro-top">
        <i class="fas fa-donate"></i>
        <a class="b-icon-title"> {{ $t("toefl.Deposit More T-Coins") }}</a>
      </div>
      <div class="b-info">
        <p class="info-tip">{{ $t("toefl.PaymentMethod") }}</p>
        <div>
          <div>
            <el-select v-model="paymentMethod" style="width:100%">
              <el-option
                v-for="payments in paymentMethods"
                :key="payments.value"
                :label="payments.label"
                :value="payments.value"
              >
              </el-option>
            </el-select>
            <el-alert
              style="margin-top:15px"
              v-if="paymentMethod === 'STRIPE'"
              type="warning"
              :closable="false"
              show-icon
            >
              <template slot="title">
                <label v-html="$t('toefl.HandlingFee')"></label>
              </template>
            </el-alert>
          </div>
        </div>
        <p class="info-tip">{{ $t("toefl.Amount") }}</p>
        <div class="trade-list-wrapper">
          <div class="row">
            <div
              class="col-sm-6 col-md-4"
              v-for="(trade, index) in trades"
              :key="index"
            >
              <div
                :class="
                  trade.selected ? 'trade-wrapper selected' : 'trade-wrapper'
                "
                @click="selectPrice(index)"
              >
                <el-tag
                  class="tags"
                  type="warning"
                  effect="dark"
                  v-show="
                    parseInt(trade.price) / parseInt(trade.original_price) < 1
                  "
                >
                  {{
                    $t("toefl.discount", {
                      num:
                        lang === "en"
                          ? 100 -
                            (parseInt(trade.price) /
                              parseInt(trade.original_price)) *
                              100
                          : (parseInt(trade.price) /
                              parseInt(trade.original_price)) *
                            10
                    })
                  }}
                </el-tag>
                <div class="text-center">
                  <b class="text-success tcoin">{{ trade.point }}</b>
                  <b class="bb-name">{{ $t("toefl.T-Coin") }}</b>
                  <hr />
                  <div style="font-size:18px">
                    <b v-if="locationIsTW">
                      ${{ showUSD(trade.stripe_usd_price) }}
                      {{ trade.price_currency }}
                      <el-tag
                        v-if="paymentMethod === 'STRIPE'"
                        class="stripe-tag"
                        type="warning"
                        size="mini"
                        effect="dark"
                      >
                        +5%
                      </el-tag>
                    </b>
                    <b v-else>
                      ≈ ${{ trade.stripe_usd_price }} USD
                      <el-tooltip
                        :content="`$${getStripePrice(trade.price)} TWD`"
                        placement="top"
                        popper-class="tooltipColor"
                      >
                        <span class="action-icon">
                          <i class="fas fa-question-circle" />
                        </span>
                      </el-tooltip>
                    </b>
                  </div>
                  <div
                    v-if="locationIsTW"
                    v-show="
                      parseInt(trade.price) / parseInt(trade.original_price) < 1
                    "
                  >
                    <small class="line"
                      >${{ Math.round(trade.original_price) }}
                      {{ trade.price_currency }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="text-center">
          <h3 class="balance-price">{{ pay.count }}</h3>
          <span class="balance-name">{{ $t("toefl.T-Coin") }}</span>
          <el-divider direction="vertical"></el-divider>
          <template v-if="locationIsTW">
            <h3 class="balance-price" v-if="paymentMethod === 'STRIPE'">
              ${{ showUSD(pay.stripe_usd_price) }}
            </h3>
            <span class="balance-name">USD</span>
          </template>
          <template v-else>
            <h3 class="balance-price">≈ ${{ pay.stripe_usd_price }}</h3>
            <span class="balance-name">USD</span>
            <el-tooltip
              :content="`$${getStripePrice(pay.price)} TWD`"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </template>
          <el-link
            @click="createOrder()"
            style="color:white!important"
            class="button"
            >{{ $t("toefl.Pay now") }}</el-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import Toefl from "@/apis/toefl.js";

export default {
  data() {
    return {
      trades: [],
      location: "TW",
      paymentMethod: "STRIPE",
      custom: {
        count: "",
        selected: false
      },
      curType: "TW",
      currency: {
        CN: {
          num: 2.5,
          name: "CNY"
        },
        TW: {
          num: 10,
          name: "TWD"
        }
      },
      pay: {
        id: 0,
        count: 0,
        price: 0,
        price_currency: "TWD"
      }
    };
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    locationIsTW() {
      return true;
    },
    paymentMethods() {
      const stripe = {
        value: "STRIPE",
        label: this.$t("toefl.Stripe")
      };
      if (!this.locationIsTW) {
        return [stripe];
      }
      return [stripe];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    PREVIOUS_ORDER() {
      return "PREVIOUS_ORDER";
    }
  },
  watch: {
    location() {
      if (this.location !== "TW") {
        this.paymentMethod = "STRIPE";
      }
    }
  },
  async mounted() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.location = iso_code;

    this.getPrice();

    const previousOrder = window.localStorage.getItem(this.PREVIOUS_ORDER);
    window.localStorage.removeItem(this.PREVIOUS_ORDER);
    if (previousOrder) {
      const previousOrderObject = JSON.parse(previousOrder);
      this.pay.id = previousOrderObject.package_id;
      this.paymentMethod = previousOrderObject.payment_method;
      this.createOrder();
    }
  },

  methods: {
    showUSD(price) {
      if (Number(price) % 1 === 0) {
        return Number(price);
      } else {
        return price;
      }
    },
    selectPrice(index) {
      this.trades.forEach(trade => {
        trade.selected = false;
      });
      if (index === -1) {
        this.custom.selected = true;
      } else {
        this.custom.selected = false;
        this.custom.count = "";
        this.trades[index].selected = true;
        this.pay.id = this.trades[index].id;
        this.pay.count = this.trades[index].point;
        this.pay.price = this.trades[index].price;
        this.pay.price_currency = this.trades[index].price_currency;
        this.pay.stripe_usd_price = this.trades[index].stripe_usd_price;
      }
    },
    setCustomPrice() {
      this.custom.count = this.custom.count.replace(/\D+/g, "");
      this.pay.count = this.custom.count;
      this.pay.price = this.custom.count * this.currency[this.curType].num;
    },
    setCustomBlur() {
      if (this.custom.count === "") {
        this.selectPrice(0);
      }
    },
    async getPrice() {
      const trades = await Toefl.openPackages();
      trades.packages.forEach(trade => {
        trade["selected"] = false;
      });
      this.trades = trades.packages;

      if (this.$route.query["t-coins"]) {
        const defaultIndex = trades.packages.findIndex(
          aPackage => aPackage.point >= this.$route.query["t-coins"]
        );
        this.selectPrice(defaultIndex);
      } else {
        this.selectPrice(0);
      }
    },
    async createOrder() {
      if (this.pay.id > 0) {
        const currentOrder = {
          package_id: this.pay.id,
          payment_method: this.paymentMethod
        };
        if (this.isLogin) {
          const orderUrl = await Toefl.createOrder(currentOrder);
          window.location.href = orderUrl;
        } else {
          window.localStorage.setItem(
            this.PREVIOUS_ORDER,
            JSON.stringify(currentOrder)
          );
          this.$router.push({ name: "Login" });
        }
      }
    },
    getStripePrice(price) {
      const handlingFee = 0.05;
      return Math.ceil(Number(price) + Number(price) * handlingFee);
    }
  }
};
</script>

<style scoped>
.b-info .balance-price.balance-USD-price {
  margin-left: 10px;
  color: #757575;
}
.b-info .balance-name.balance-USD-name {
  color: #757575;
}
.balance-card {
  margin-bottom: 20px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 0 28px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
.b-info .button {
  width: 140px;
  height: 45px;
  float: right;
  background: var(--themeColor);
  border-radius: 4px;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.trade-wrapper > div {
  display: table;
  /* margin: 18px auto 0; */
  padding: 10px 20px;
  width: 100%;
}
.trade-wrapper .bb-name {
  margin-left: 5px;
  font-size: 18px;
}
.trade-wrapper > div > div {
  margin-top: 8px;
  font-size: 14px;
  color: #757575;
  line-height: 14px;
}
.trade-wrapper hr {
  margin: 10px 0;
}
.trade-wrapper.selected {
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
  background: #effff6;
  box-shadow: 0 2px 12px 0 #effff6;
}

.trade-custom {
  width: 200px;
  margin-right: 0;
}
.trade-custom .tips {
  margin: 29px 0 0 18px;
  font-size: 14px;
  color: #999;
  letter-spacing: 0;
}
.trade-list-wrapper {
  margin-top: 20px;
}
.trade-wrapper {
  position: relative;
  width: 100%;
  height: 140px;
  margin-right: 16px;
  margin-bottom: 30px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  font-size: 24px;
  color: #222;
  letter-spacing: 0;
  line-height: 24px;
  float: left;
  cursor: pointer;
  padding: 20px 0 0 0;
}
.tags {
  position: absolute;
  left: -2px;
  top: -15px;
  height: 30px;
  padding: 0 15px;
  line-height: 28px;
  border-radius: 15px 0 15px 0;
  font-size: 18px;
  font-weight: 700;
}
.line {
  color: #999999;
  text-decoration: line-through;
}
.tcoin {
  font-size: 40px;
}
@media screen and (max-width: 400px) {
  .b-info .button[data-v-5b978556] {
    width: 100%;
    margin-bottom: 15px;
  }
  .b-info {
    padding: 0 15px;
  }
}
</style>
